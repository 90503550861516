import React from 'react';
import { FaEdit } from "react-icons/fa";
import '../../styles/components.css';

export const ImageComponent = ({ image }) => {
	return(
		<div className='image-container'>
			<img src={image} alt='Bien' className='product-image' />
		</div>
	);
};