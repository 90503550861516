import {
    UPLOAD_GOOD_SUCCESS,
    UPLOAD_GOOD_FAIL,
    GET_GOODS_SUCCESS,
    GET_GOODS_FAIL,
    GET_GOOD_SUCCESS,
    GET_GOOD_FAIL,
    MODIFY_GOOD_SUCCESS,
    MODIFY_GOOD_FAIL,
    SET_GOOD_LOADING,
    REMOVE_GOOD_LOADING,
    GET_PROFILE_GOOD_SUCCESS,
    GET_PROFILE_GOOD_FAIL,
} from '../actions/types'


const initialState = {
    goods: [],
    good: null,
    profile: null,
    goodLoading: false,
    uploadGood: null,
    goToPayment: false,
    updatedGood: null
}

export default function Good(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case SET_GOOD_LOADING:
            return {
                ...state,
                goodLoading: true
            };
        case REMOVE_GOOD_LOADING:
            return {
                ...state,
                goodLoading: false
            };
        case UPLOAD_GOOD_SUCCESS:
            return {
                ...state,
                goods: [...state.goods, payload.good],
                uploadGood: payload.updatedGood,
                goToPayment: true
            };
        case UPLOAD_GOOD_FAIL:
            return {
                ...state,
                uploadGood: null,
                goToPayment: false
            };
        case GET_GOODS_SUCCESS:
            return {
                ...state,
                goods: payload.goods
            };
        case GET_GOODS_FAIL:
            return {
                ...state,
                goods: []
            };
        case GET_GOOD_SUCCESS:
            return {
                ...state,
                good: payload.good
            };
        case GET_PROFILE_GOOD_SUCCESS:
            return {
                ...state,
                profile: payload.user_profile
            };
        case GET_GOOD_FAIL:
            return {
                ...state,
                good: null
            };
        case GET_PROFILE_GOOD_FAIL:
            return {
                ...state,
                profile: null
            };
        case MODIFY_GOOD_SUCCESS:
            return {
                ...state,
                updatedGood: payload.modifiedGood
            };
        case MODIFY_GOOD_FAIL:
            return {
                ...state,
                updatedGood: null
            };
        default:
            return state
    };
}