import React from 'react';

export const ParallaxSection = ({ title, text }) => {
  return (
    <div className="parallax-section">
      <div className="parallax-background"></div>
      <div className="parallax-content">
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};