import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/components.css';
import { ImageComponent } from './ImageComponent.js';
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import movil1 from '../../images/movil1.jpg';
import movil2 from '../../images/movil2.jpg';


export const Gallery = ({ imageUrl }) => {
	const [currentImageIndex, setCurrentImageIndex] = useState(0);


	const handleEdit = (index) => {
		console.log('Editando imagen ${index}');
	};

	const nextImage = () => {
		setCurrentImageIndex((prevIndex) => (prevIndex === imageUrl.length - 1 ? 0 : prevIndex + 1));
	};

	const prevImage = () => {
		setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? imageUrl.length - 1 : prevIndex - 1));
	};

	return(
		<div className='container-image-gallery'>
			<button onClick={prevImage} className='chevron-gallery-left'><FaChevronLeft /></button>
			<ImageComponent image={imageUrl[currentImageIndex]} onEdit={() => handleEdit(currentImageIndex)} />
			<button onClick={nextImage} className='chevron-gallery-right'><FaChevronRight /></button>
		</div>
	);
};

Gallery.propTypes = {
  imageUrl: PropTypes.arrayOf(PropTypes.string).isRequired,
};