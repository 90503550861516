import Layout from '../../hocs/Layout';
import { AnimatedText } from '../../components/aditional/AnimatedText.js';
import { FAQ } from '../../components/aditional/Faq.js';
import '../../styles/static-pages.css';
import { ParallaxSection } from '../../components/aditional/ParallaxSection.js';
import { useEffect, useState } from 'react';
import { WhatsAppButton } from '../../components/aditional/WhatsApp';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';


const About = () => {

	useEffect(() => {
      window.scrollTo(0,0)
    }, [])

	const [advantagesVisible1, setAdvantagesVisible1] = useState(false);
	const [advantagesVisible2, setAdvantagesVisible2] = useState(false);
	const [advantagesVisible3, setAdvantagesVisible3] = useState(false);

	const toggleAdvantages1 = () => {
		setAdvantagesVisible1(!advantagesVisible1);
	};

	const toggleAdvantages2 = () => {
		setAdvantagesVisible2(!advantagesVisible2);
	};

	const toggleAdvantages3 = () => {
		setAdvantagesVisible3(!advantagesVisible3);
	};
    
	return (
		<Layout>
			<div className='container-about-page'>
				<div className='container container-intro'>
					<AnimatedText text='¿Por qué registrar tus bienes?' className='animated-intro' />
					<div className='intro-items'>
						<div className='intro-paragraphs'>
							<p>Registrar tus bienes es importante por varias razones. En primer lugar, el registro de tus bienes proporciona una prueba 
							documental de identidad, lo que puede ser crucial en caso de robo, pérdida o disputas legales. Al tener un registro, puedes 
							demostrar que eres el propietario legítimo de esos bienes.</p>
							<p>Además, puede ser útil para propositos de reclamos en el seguro.</p>
							<p>El registro de bienes es importante para proteger tus posesiones y tener un mejor control sobre tu patrimonio.</p>
							<p>A partir de este concepto pensamos en usted, el registrar los bienes es importante para su posterior identificación de 
							manera sencilla.</p>
						</div>
						<img src={require(`../../images/about-img.webp`)} />
					</div>
				</div>
				<ParallaxSection title='¿Qué es el registro de bienes muebles?' text ='Es un sistema que permite IDENTIFICAR objetos definidos como 
				bienes muebles, mediante un código QR único, que permite asociarlo de manera digital con el sistema, con el fin de proporcionarle 
				identificación a aquellos objetos, herramientas de mano, electrodomésticos, entre otros, no registrados por otro medio. Esto permite 
				a cualquier ciudadano, saber el origen de estos objetos con solo escanear el código QR con un teléfono. De esta manera, ante una 
				pérdida, extravío, sustracción, etc., se pueda saber de quién es dicho objeto de manera inmediata.' />
				<div className='background-blue'>
					<div className='container container-origin'>
						<p className='origin-text'>El Registro de Bienes Muebles nace de la necesidad de proporcionar un medio confiable y accesible
						para documentar con detalles herramientas, electrodomésticos, entre otras posesiones que las personas tienen. Uno de los 
						factores que motiva esta necesidad es la protección y seguridad.
						En un mundo donde el robo, la pérdida y los préstamos de bienes representan riesgos reales, contar con un registro formal de 
						las posesiones proporciona protección y seguridad. En caso de robo, préstamo o extravío,  la falta de identificación 
						dificulta su seguimiento y recuperación; por lo tanto el Registro de Bienes Muebles tiene como objetivo proporcionar un 
						medio para identificar y distinguir claramente estos elementos, contribuyendo a su protección.</p>
						<h3 className='origin-title'>El registro nace de una necesidad</h3>
					</div>
				</div>
				<div className='container-dark'>
					<div className='container container-how'>
						<h3 className='how-title'>¿Cómo registramos bienes?</h3>
						<p className='how-text'>Una vez completado el registro con los datos personales, se ingresa la información detallada de tus 
						bienes siguiendo los pasos de la página, la plataforma te proporciona un código QR único para cada uno de tus bienes 
						registrados, luego podrás escanear el código QR para acceder rápidamente a la información detallada de tus bienes en cualquier 
						momento. Recuerda que siempre es importante mantener actualizada la información en la plataforma para reflejar cualquier cambio
						en tus bienes.
						</p>
					</div>
				</div>
				<div className='background-gray'>
					<div className='container container-considerations'>
						<h3 className='considerations-title'>Consideraciones Generales</h3>
						<div className='container-entities'>
							<img src={require(`../../images/fabrica.webp`)} className='entity-image' />
							<div className='container-entity'>
								<h4 className='entity-title'>Optimizando la Gestión Empresarial</h4>
								<p className='advantage-paragraph'>En el dinámico entorno empresarial actual, la eficaz gestión de los activos es fundamental para garantizar la 
								continuidad operativa y el crecimiento sostenible. En este contexto, la implementación de un riguroso sistema 
								de registro de activos emerge como una práctica esencial para empresas y PYMEs que buscan optimizar sus procesos 
								internos y asegurar un control efectivo de sus recursos.
								</p>
								<div className='container-advantages'>
									<h5 className='contain-toggle' onClick={toggleAdvantages1}>Ventajas en el Ámbito Empresarial {advantagesVisible1 ? <FaAngleUp /> : <FaAngleDown />}</h5>
									{advantagesVisible1 &&
									<div className='advantage-item'>
										<p className='advantage-paragraph'><b>Identificación Clara de Propiedad:</b> El registro meticuloso de activos permite a las empresas 
										distinguir claramente entre los bienes de la organización y los elementos personales de los empleados. 
										Esto resulta fundamental en situaciones como préstamos internos o pérdidas, donde la delimitación de la 
										propiedad evita confusiones y conflictos.
										</p>
										<p className='advantage-paragraph'><b>Control de Distribución y Uso:</b> Al mantener un registro detallado de los activos, las empresas pueden 
										rastrear fácilmente la ubicación y el estado de los mismos. Esto facilita la gestión de préstamos internos 
										entre departamentos o sucursales, así como la supervisión del uso adecuado de los recursos por parte de los 
										empleados.
										</p>
										<p className='advantage-paragraph'><b>Optimización de Recursos:</b> Con un registro centralizado de activos, las empresas pueden identificar 
										oportunidades de optimización y racionalización de recursos. Al conocer la disponibilidad y el uso de 
										cada activo, se pueden realizar ajustes para minimizar costos y maximizar la eficiencia operativa.
										</p>
										<p className='advantage-paragraph'><b>Cumplimiento Normativo:</b> El mantenimiento de registros precisos de los activos contribuye al cumplimiento 
										de normativas y regulaciones internas y externas. Esto es especialmente relevante en sectores altamente 
										regulados, donde la transparencia y la trazabilidad son requisitos fundamentales.
										</p>
										<p className='advantage-paragraph'><b>Facilita la Toma de Decisiones:</b> Con acceso a información actualizada sobre la distribución y el estado 
										de los activos, los líderes empresariales pueden tomar decisiones informadas y estratégicas. Esto incluye 
										la planificación de inversiones, la asignación de recursos y la identificación de áreas de mejora.
										</p>
									</div>}
								</div>
								<p className='advantage-paragraph'>En resumen, la implementación de un sistema de registro de activos en el entorno empresarial ofrece una serie de 
								beneficios tangibles que van desde la optimización de recursos hasta el fortalecimiento del cumplimiento normativo, 
								contribuyendo así a la eficacia operativa y al crecimiento sostenible de la organización.
								</p>
							</div>
						</div>
						<div className='container-entities  back-entity'>
							<div className='container-entity'>
								<h4 className='entity-title'>Fortaleciendo la Protección Patrimonial en Agencias de Seguros</h4>
								<p className='advantage-paragraph'>En el ámbito de las agencias de seguros, la implementación de un sistema de 
								registro de bienes emerge como una herramienta fundamental para fortalecer la protección patrimonial 
								de los asegurados y optimizar la gestión de riesgos. Este enfoque proactivo no solo permite registrar y diferenciar 
								los activos a asegurar, como bicicletas, teléfonos celulares, televisores, entre otros, sino que también ofrece 
								una serie de ventajas significativas tanto para las agencias como para sus clientes.
								</p>
								<div className='container-advantages'>
									<h5 className='contain-toggle' onClick={toggleAdvantages2}>Ventajas en el Ámbito de las Agencias de Seguros {advantagesVisible2 ? <FaAngleUp /> : <FaAngleDown />}</h5>
									{advantagesVisible2 &&
									<div className='advantage-item'>
										<p className='advantage-paragraph'><b>Identificación Precisa de Bienes Asegurables:</b> El registro detallado de 
										los bienes personales permite a las agencias de seguros identificar de manera precisa los activos a asegurar, 
										lo que facilita la elaboración de pólizas personalizadas y adaptadas a las necesidades específicas de cada 
										cliente.
										</p>
										<p className='advantage-paragraph'><b>Mitigación de Riesgos y Fraudes:</b> Al registrar los bienes asegurables y 
										verificar su procedencia legal, las agencias de seguros pueden reducir el riesgo de fraude y mitigar 
										pérdidas asociadas a reclamaciones fraudulentas o activos de origen ilícito. Esto contribuye a mantener 
										la integridad del sistema de seguros y a proteger los intereses de los asegurados.
										</p>
										<p className='advantage-paragraph'><b>Fomento de la Conciencia Patrimonial:</b> El proceso de registro 
										promueve la conciencia patrimonial entre los asegurados, alienta la transparencia en la declaración de 
										activos y fortalece la cultura de la legalidad en la adquisición de bienes. Esto, a su vez, contribuye 
										a una mayor confianza y credibilidad en el mercado de seguros.
										</p>
										<p className='advantage-paragraph'><b>Asesoramiento Legal Fundamentado:</b> Al respaldarse en asesoramiento 
										legal especializado, las agencias de seguros pueden ofrecer a sus clientes la certeza de que sus prácticas 
										cumplen con todas las normativas y regulaciones pertinentes. Esto genera confianza y seguridad tanto para 
										la agencia como para los asegurados.
										</p>
										<p className='advantage-paragraph'><b>Optimización de Procesos:</b> La implementación de un sistema de 
										registro eficiente optimiza los procesos internos de las agencias de seguros, agilizando la gestión de 
										pólizas, reclamaciones y renovaciones. Esto permite una atención más ágil y personalizada, mejorando la 
										experiencia del cliente.
										</p>
									</div>}
								</div>
								<p className='advantage-paragraph'>En conclusión, el registro de bienes en agencias de seguros ofrece una serie de beneficios 
								que van más allá de la simple identificación de activos asegurables. Desde la mitigación de riesgos hasta el fomento de la 
								conciencia patrimonial, esta práctica se presenta como un componente clave en la optimización de la gestión de seguros y 
								la protección de los intereses de los asegurados.
								</p>
							</div>
							<img src={require(`../../images/compania-de-seguros.webp`)} className='entity-image' />
						</div>
						<div className='container-entities'>
							<img src={require(`../../images/grupo.webp`)} className='entity-image' />
							<div className='container-entity'>
								<h4 className='entity-title'>Protegiendo los Intereses Individuales y Familiares</h4>
								<p className='advantage-paragraph'>Para las personas, la adopción de un sistema de registro de bienes muebles 
								representa una medida proactiva para salvaguardar sus activos y proteger sus intereses frente a situaciones como 
								robos, extravíos y otros eventos adversos. Este enfoque no solo brinda tranquilidad y seguridad, sino que también 
								ofrece una serie de ventajas tangibles que impactan positivamente en la calidad de vida y en la gestión de los 
								recursos individuales y familiares.
								</p>
								<div className='container-advantages'>
									<h5 className='contain-toggle' onClick={toggleAdvantages3}>Ventajas para las Personas {advantagesVisible3 ? <FaAngleUp /> : <FaAngleDown />}</h5>
									{advantagesVisible3 &&
									<div className='advantage-item'>
										<p className='advantage-paragraph'><b>Prevención de Pérdidas y Robos:</b> El registro detallado de los bienes 
										personales permite a las personas prevenir pérdidas y robos al tener un inventario claro de sus posesiones. 
										En caso de sustracciones, este registro facilita la identificación y recuperación de los bienes, así como 
										el proceso de reclamación ante autoridades y compañías de seguros.
										</p>
										<p className='advantage-paragraph'><b>Facilita la Gestión Patrimonial:</b> Mantener un registro organizado 
										de los bienes personales simplifica la gestión patrimonial y financiera. Esto incluye la evaluación del 
										valor neto, la planificación de seguros adecuados y la gestión eficiente de inventarios domésticos, lo que 
										contribuye a una mayor estabilidad económica y bienestar familiar.
										</p>
										<p className='advantage-paragraph'><b>Agiliza Procesos Administrativos:</b> Con un registro actualizado de sus 
										bienes, las personas pueden agilizar diversos procesos administrativos, como la solicitud de indemnizaciones 
										por pérdidas cubiertas por seguros, la presentación de reclamaciones ante autoridades competentes y la gestión 
										de trámites legales relacionados con sus posesiones.
										</p>
										<p className='advantage-paragraph'><b>Promueve la Responsabilidad Personal:</b> El acto de registrar y mantener 
										actualizado el inventario de bienes fomenta la responsabilidad personal y la conciencia sobre la importancia 
										de proteger los activos individuales. Esto incluye la adopción de medidas preventivas, como el uso de 
										dispositivos de seguridad y el almacenamiento seguro de objetos de valor.
										</p>
										<p className='advantage-paragraph'><b>Ofrece Tranquilidad y Seguridad:</b> Contar con un registro detallado 
										de los bienes personales brinda tranquilidad y seguridad a los individuos y sus familias, al saber que están 
										preparados para enfrentar eventualidades imprevistas. Esta sensación de protección contribuye al bienestar 
										emocional y al desarrollo de una mentalidad resiliente frente a los desafíos.
										</p>
									</div>}
								</div>
								<p className='advantage-paragraph'>En resumen, el registro de bienes muebles no solo es una práctica útil para las 
								empresas y agencias de seguros, sino que también ofrece una serie de beneficios significativos para las personas 
								comunes. Desde la prevención de pérdidas hasta la promoción de la responsabilidad personal, esta medida se presenta 
								como un recurso invaluable para proteger los intereses individuales y familiares en un mundo cada vez más dinámico y 
								complejo.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='container-back-about'>
					<div className='container container-conclusions'>
						<h3 className='conclusions-title'>Asegurando, Organizando y Optimizando</h3>
						<p className='conclusions-text'>El registro de bienes muebles emerge como una práctica esencial en diversos ámbitos, desde empresas 
						hasta agencias de seguros y personas comunes. En el ámbito empresarial, facilita la identificación clara de propiedad, 
						optimiza la gestión de recursos y promueve el cumplimiento normativo, fortaleciendo así la eficiencia operativa y la toma 
						de decisiones estratégicas. Para las agencias de seguros, el registro de bienes personales permite una gestión más eficaz 
						de riesgos, promueve la transparencia y fortalece la confianza del cliente. A nivel personal, ofrece seguridad contra 
						pérdidas y robos, simplifica la gestión patrimonial y fomenta la responsabilidad individual. En conclusión, la 
						implementación de un sistema de registro de bienes no solo ofrece beneficios tangibles en términos de protección y 
						organización, sino que también contribuye al desarrollo de una cultura de seguridad y responsabilidad en todos los ámbitos 
						de la vida.
						</p>
					</div>
				</div>
				<div className='container container-faqs'>
					<h3 className='faqs-title'>Preguntas Frecuentes</h3>
					<FAQ />
				</div>
			</div>
			<WhatsAppButton />
		</Layout>
	);
};

export default About;