import Layout from '../../hocs/Layout';
import { connect } from 'react-redux';
import { get_good, modify_good } from "../../redux/actions/good";
import { useState, useEffect, useRef } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import '../../styles/goods.css'
import { useParams } from 'react-router';
import { Navigate } from 'react-router';
import { Oval } from 'react-loader-spinner';
import { IoMdClose } from 'react-icons/io';
import imageCompression from 'browser-image-compression';


const EditGood = ({
	get_good,
	good,
	isAuthenticated,
	goodLoading,
	modify_good
})=>{

	const params = useParams()
    const goodId = params.goodId

	useEffect(() => {
      	window.scrollTo(0,0)
        get_good(goodId)
    }, [])

    const [redirect, setRedirect] = useState(false)
	const [formData, setFormData] = useState({
        name: '',
        description: '',
        brand: '',
        model: '',
        serial: '',
        size: '',
        color: '',
    });

    const mainImageInputRef = useRef(null);
    const backImageInputRef = useRef(null);
    const [mainImage, setMainImage] = useState('');
    const [backImage, setBackImage] = useState('');
    const [error, setError] = useState('');
    const [postMainImage, setPostMainImage] = useState(null);
    const [postBackImage, setPostBackImage] = useState(null);

    useEffect(() => {
        if (good) {
            setFormData({
                name: good.name || '',
                description: good.description || '',
                brand: good.brand || '',
                model: good.model || '',
                serial: good.serial || '',
                size: good.size || '',
                color: good.color || ''
            });
            setMainImage(good.main_image);
            setBackImage(good.back_image);
        }
    }, [good]);

    const loadImage = async (event, setImage, inputName) => {
        const file = event.target.files[0];
        const options = {
        	maxSizeMB: 1,
        	maxWidthOrHeight: 1920,
        	useWebWorker: true,
        }
        try {

            if (file) {
            	const compressedFile = await imageCompression(file, options);

            	const compressedImageFile = new File([compressedFile], file.name, {
	                type: file.type,
	                lastModified: file.lastModified,
	            });

		        if (inputName === 'mainImage') {
		        	setPostMainImage({
		        		image: compressedImageFile,
		        	});
		        }
		        if (inputName === 'backImage') {
		        	setPostBackImage({
		        		image: compressedImageFile,
		        	});
		        }

		        const reader = new FileReader();
		        reader.onload = () => {
		            setImage({
		                file: compressedImageFile,
		                previewURL: reader.result
		            });
		        };

		        reader.onerror = () => {
		            setError('Error al cargar la imagen. Inténtalo de nuevo más tarde.');
		        };

	            reader.readAsDataURL(compressedImageFile);
	        }
        } catch (error) {
            setError('Error al cargar la imagen. Inténtalo de nuevo más tarde.');
        }
    };

	const handleChange = (e) => {
        
        const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value
            });
    };

    const handleDelete = (imageKey, setImage, setPostImage, inputRef) => {
        setImage(null);
        setPostImage(null);
        inputRef.current.value = '';
    }

	const onSubmit = async (e) =>{
    	e.preventDefault();
    	if (postMainImage) {
	        const imageData = {
	            mainImage: postMainImage,
	            backImage: postBackImage
	        };
	    	await modify_good(goodId, formData, imageData);
	    	setRedirect(true);
	    }
    	
  	};

  	if(!isAuthenticated)
        return <Navigate to="/" />

	return(
		<Layout>
			<div className='container-form'>
				<form className='form-content' onSubmit={e => onSubmit(e)}>
					<Link to={`/goods/good/${goodId}`} className='return-button' style={{textDecoration: 'none'}}><FaArrowLeft />Regresar</Link>
					<img className='img-sign' src={require(`../../images/logorbm.png`)} />
						<h3 className='form-title'>Modificar bien</h3>
					<div className='form-section-cont'>
				        <input
				            type='text'
				            name='name'
				            value={formData.name}
				            onChange={handleChange}
				            placeholder='Nombre del bien'
				            className='input-form'
				        />
			        </div>
			        <div className='form-section-cont'>
				        <textarea
				            type='text'
				            name='description'
				            value={formData.description}
				            onChange={handleChange}
				            placeholder='Descripción'
				            className='input-form'
				        />
			        </div>
			        <div className='form-section-cont'>
						<input
				            type='text'
				            name='brand'
				            value={formData.brand}
				            onChange={handleChange}
				            placeholder='Marca'
				            className='input-form'
				        />
			        </div>
			        <div className='form-section-cont'>
				        <input
				            type='text'
				            name='model'
				            value={formData.model}
				            onChange={handleChange}
				            placeholder='Modelo'
				            className='input-form'
				        />
			        </div>
			        <div className='form-section-cont'>
				        <input
				            type='text'
				            name='serial'
				            value={formData.serial}
				            onChange={handleChange}
				            placeholder='Nº de Serie'
				            className='input-form'
				        />
			        </div>
			        <div className='form-section-cont'>
				        <input
				            type='text'
				            name='size'
				            value={formData.size}
				            onChange={handleChange}
				            placeholder='Talla'
				            className='input-form'
				        />
			        </div>
			        <div className='form-section-cont'>
				        <input
				            type='text'
				            name='color'
				            value={formData.color}
				            onChange={handleChange}
				            placeholder='Color'
				            className='input-form'
				            maxlenght='50'
				        />
			        </div>
			        <div className="preview">
                        <h3>Vista previa</h3>
                        <div className="image-preview">
                        	<div className='newgood-image-container'>
	                            {mainImage && <div className='preview-image-container'>
	                                <img src={mainImage.previewURL || mainImage} alt="Preview 1" />
	                                <button type="button" className='delete-button' onClick={() => handleDelete('mainImage', setMainImage, setPostMainImage, mainImageInputRef)}><IoMdClose /></button>
	                            </div>}
								{!mainImage && <div className="placeholder">Imagen principal*</div>}
								<input type="file" name='mainImage' ref={mainImageInputRef} onChange={(e) => loadImage(e, setMainImage, 'mainImage')} />
							</div>
							<div className='newgood-image-container'>
	                            {backImage && <div className='preview-image-container'>
	                                <img src={backImage.previewURL || backImage} alt="Preview 2" />
	                                <button type="button" className='delete-button' onClick={() => handleDelete('backImage', setBackImage, setPostBackImage, backImageInputRef)}><IoMdClose /></button>
	                            </div>}
	                            {!backImage && <div className="placeholder">Imagen Trasera</div>}
	                            <input type="file" name='backImage' ref={backImageInputRef} onChange={(e) => loadImage(e, setBackImage, 'backImage')} />
                            </div>
                        </div>
                        {goodLoading ? 
							<button type='button' className='submit-button'><Oval
			  					visible={true}
								height="25"
								width="25"
								color="#ddd"
								ariaLabel="oval-loading"
								wrapperStyle={{}}
								wrapperClass=""
								secondaryColor="#ddd"
								/>
							</button> : 
							<button type='submit' className='submit-button'>
								Modificar	
							</button>
						}
                    </div>
		        </form>
	        </div>
		</Layout>
	)
};

const mapStateToProps = state => ({
	good: state.Good.good,
	isAuthenticated: state.Auth.isAuthenticated,
	goodLoading: state.Good.goodLoading
})

export default connect(mapStateToProps, {
	get_good,
	modify_good,
}) (EditGood)