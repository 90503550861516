import React, { useState, useRef } from 'react';
import Layout from '../../hocs/Layout';
import { connect } from 'react-redux';
import { upload_id_images } from '../../redux/actions/auth';
import { Navigate } from 'react-router';
import '../../styles/auth.css';
import { useParams } from 'react-router';
import { IoMdClose } from 'react-icons/io';
import { Oval } from 'react-loader-spinner';


const UploadID = ({
    upload_id_images, 
    loading,
    cardImageUploaded,
    isAuthenticated
}) => {
    const [formData, setFormData] = useState({
        front_image: null,
        back_image: null,
        selfie_with_card: null
    });

    const params = useParams()
    const userId = params.userId
    const { front_image, back_image, selfie_with_card } = formData;
    const frontImageInputRef = useRef(null);
    const backImageInputRef = useRef(null);
    const selfieWithCardInputRef = useRef(null);
    const [frontImage, setFrontImage] = useState('');
    const [backImage, setBackImage] = useState('');
    const [selfieWithCard, setSelfieWithCard] = useState('');
    const [postFrontImage, setPostFrontImage] = useState(null);
    const [postBackImage, setPostBackImage] = useState(null);
    const [postSelfieWithCard, setPostSelfieWithCard] = useState(null);
    const [error, setError] = useState('');
    const [redirect, setRedirect] = useState(false);

    const loadImage = async (event, setImage, inputName) => {
        const file = event.target.files[0];
        if (inputName === 'frontImage') {
            setPostFrontImage({
                image: event.target.files,
            });
        }
        if (inputName === 'backImage') {
            setPostBackImage({
                image: event.target.files,
            });
        }
        if (inputName === 'selfieWithCard') {
            setPostSelfieWithCard({
                image: event.target.files,
            });
        }
        const reader = new FileReader();

        reader.onload = () => {
            setImage({
                file: file,
                previewURL: reader.result
            });
        };

        reader.onerror = () => {
            setError('Error al cargar la imagen. Inténtalo de nuevo más tarde.');
        };

        try {

            if (file) {
                reader.readAsDataURL(file);
            }
        } catch (error) {
            setError('Error al cargar la imagen. Inténtalo de nuevo más tarde.');
        }
    };

    const handleDelete = (imageKey, setImage, setPostImage, inputRef) => {
        setImage(null);
        setPostImage(null);
        inputRef.current.value = '';
    }

    const onSubmit = e => {
        e.preventDefault();
        if (postFrontImage && postBackImage && postSelfieWithCard) {
            const imageData = {
                frontImage: postFrontImage,
                backImage: postBackImage,
                selfieWithCard: postSelfieWithCard
            };
            upload_id_images(imageData, userId);
        }
        else {
            setError('Asegurse de cargar frente y dorso del DNI')
        }
    };

    if (cardImageUploaded && !loading)
        return <Navigate to="/" />

    return (
        <Layout>
            <div className='container-form-auth'>
                <form className='form-card-content' onSubmit={e => onSubmit(e)}>
                    <img className='img-sign' src={require(`../../images/logorbm.png`)} />
                    <h3 className='form-title'>Verificar registro</h3>
                    <div className='container-selfie-image'>
                        <div className='card-image-container'>
                            {selfieWithCard && <div className='selfiepreview-image-contain'>
                                <img src={selfieWithCard.previewURL || selfieWithCard} alt="Preview 2" />
                                <button type="button" className='delete-button' onClick={() => handleDelete('selfieWithCard', setSelfieWithCard, setPostSelfieWithCard, selfieWithCardInputRef)}><IoMdClose /></button>
                            </div>}
                            {!selfieWithCard && <div className='selfie-placeholder'>Selfie con el Documento</div>}
                            <input type="file" name='selfieWithCard' ref={selfieWithCardInputRef} onChange={(e) => loadImage(e, setSelfieWithCard, 'selfieWithCard')} />
                        </div>
                    </div>
                    <div className='container-card-id'>
                        <div className='card-image-container'>
                            {frontImage && <div className='cardpreview-image-container'>
                                <img src={frontImage.previewURL || frontImage} alt="Preview 1" />
                                <button type="button" className='delete-button' onClick={() => handleDelete('frontImage', setFrontImage, setPostFrontImage, frontImageInputRef)}><IoMdClose /></button>
                            </div>}
                            {!frontImage && <div className="card-placeholder">Frente</div>}
                            <input type="file" name='frontImage' ref={frontImageInputRef} onChange={(e) => loadImage(e, setFrontImage, 'frontImage')} />
                        </div>
                        <div className='card-image-container'>
                            {backImage && <div className='cardpreview-image-container'>
                                <img src={backImage.previewURL || backImage} alt="Preview 2" />
                                <button type="button" className='delete-button' onClick={() => handleDelete('backImage', setBackImage, setPostBackImage, backImageInputRef)}><IoMdClose /></button>
                            </div>}
                            {!backImage && <div className="card-placeholder">Dorso</div>}
                            <input type="file" name='backImage' ref={backImageInputRef} onChange={(e) => loadImage(e, setBackImage, 'backImage')} />
                        </div> 
                    </div>
                    {error && <div className='error-message'>{error}</div>}
                    {loading ? 
                        <button type='button' className='button-form'><Oval
                            visible={true}
                            height="25"
                            width="25"
                            color="#ddd"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            secondaryColor="#ddd"
                            />
                        </button> : 
                        <button type='submit' className='button-form'>
                            Enviar   
                        </button>
                    }
                </form>
            </div>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: state.Auth.loading,
    cardImageUploaded: state.Auth.cardImageUploaded,
    isAuthenticated: state.Auth.isAuthenticated
});

export default connect(mapStateToProps, {
    upload_id_images
})(UploadID);