import Layout from '../../hocs/Layout';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { login } from '../../redux/actions/auth';
import { Oval } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router';
import '../../styles/auth.css';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";


const Login = ({
 	login, 
 	loading,
 	isAuthenticated
}) => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const [formData, setFormData] = useState({
		email: '',
		password: ''
	});

	const {
		email,
		password
	} = formData;

	const [activated, setActivated] = useState(false);
	const [passwordType, setPasswordType] = useState('password');
	const [iconShow, setIconShow] = useState(false);

	const onChange = e => setFormData({...formData, [e.target.name]: e.target.value });

	const onSubmit = e =>{
    	e.preventDefault();
    	login(email, password);
    	setActivated(true);
    	window.scrollTo(0,0)
  	};

  	const handleShowPassword = () => {
  		if (passwordType === 'password') {
  			setPasswordType('text')
  			setIconShow(!iconShow)
  		}
  		else if (passwordType === 'text') {
  			setPasswordType('password')
  			setIconShow(!iconShow)
  		}
  		
  	}

  	if (activated && !loading && isAuthenticated)
    	return <Navigate to='/' />;

	return (
		<Layout>
			<div className='container-form-auth'>
				<form className='form-content' onSubmit={e => onSubmit(e)}>
						<img className='img-sign' src={require(`../../images/logorbm.png`)} />
						<h3 className='form-title'>Inicio de Sesión</h3>
						<div className='form-section-auth'>
							<label htmlFor='email'>Correo Electrónico</label>
							<input
							name='email'
							value={email}
							onChange={e => onChange(e)}
							type='email'
							required
							className='input-form' />
						</div>
						<div className='form-section-auth password-button'>
							<label htmlFor='password'>Contraseña</label>
							<input
							name='password'
							value={password}
							onChange={e => onChange(e)}
							type={passwordType}
							required
							className='input-form' />
							{iconShow ? (
								<FaRegEyeSlash onClick={handleShowPassword} className='password-show-hide' />
							) : (
								<FaRegEye onClick={handleShowPassword} className='password-show-hide' />
							)}
						</div>
						{loading ? 
							<button type='button' className='button-form'><Oval
			  					visible={true}
								height="25"
								width="25"
								color="#ddd"
								ariaLabel="oval-loading"
								wrapperStyle={{}}
								wrapperClass=""
								secondaryColor="#ddd"
								/>
							</button> : 
							<button type='submit' className='button-form'>
								Iniciar Sesión	
							</button>
						}
					<div className="container-forgot">
			             <Link to="/reset_password" className='forgot-password' style={{textDecoration: 'none'}}>
			                ¿Olvidaste tu contraseña?
			        	</Link>
			        </div>
			        <div>
						<p className='container-to-signup'>¿Aun no tienes una cuenta?<Link to='/signup' className='forgot-password' style={{textDecoration: 'none'}}>Registrate</Link></p>
					</div>
				</form>
			</div>
		</Layout>
	);
}

const mapStateToProps = state => ({
	loading: state.Auth.loading,
	isAuthenticated: state.Auth.isAuthenticated
})

export default connect(mapStateToProps, {
	login 
}) (Login)