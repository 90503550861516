import {
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAIL,
    UPDATE_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_FAIL,
    SET_PROFILE_LOADING,
    REMOVE_PROFILE_LOADING,
} from './types';
import axios from 'axios';
import { setAlert } from './alert';


export const get_user_profile = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        };

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/profile/user`, config);

            if (res.status === 200) {
                dispatch({
                    type: GET_USER_PROFILE_SUCCESS,
                    payload: res.data
                });
            } else {
                dispatch({
                    type: GET_USER_PROFILE_FAIL
                });
            }
        } catch(err) {
            dispatch({
                type: GET_USER_PROFILE_FAIL
            });
        }
    }
}


export const update_user_profile = (profileData, imageData) => async dispatch => {
    dispatch({
        type: SET_PROFILE_LOADING
    });
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Content-Type': 'multipart/form-data'
            }
        };

        const sendData = new FormData();
        sendData.append('address', profileData.address);
        if (imageData.photo) {
            sendData.append('photo', imageData.photo.image);
        }
        sendData.append('city', profileData.city);
        sendData.append('state_province_region', profileData.state_province_region);
        sendData.append('phone', profileData.phone);
        sendData.append('country_region', profileData.country_region);

        try {
            const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/profile/update`, sendData, config);

            if (res.status === 200) {
                dispatch({
                    type: UPDATE_USER_PROFILE_SUCCESS,
                    payload: res.data
                });
                dispatch(setAlert('Perfil actualizado correctamente', 'good'));
            } else {
                dispatch({
                    type: UPDATE_USER_PROFILE_FAIL
                });
                dispatch(setAlert('Error al actualizar el perfil', 'bad'));
            }
            dispatch({
                type: REMOVE_PROFILE_LOADING
            });
        } catch(err) {
            dispatch({
                type: UPDATE_USER_PROFILE_FAIL
            });
            dispatch({
                type: REMOVE_PROFILE_LOADING
            });
            dispatch(setAlert('Error al actualizar el perfil por problema del servidor', 'bad'));
        }
    } else {
        dispatch({
            type: UPDATE_USER_PROFILE_FAIL
        });
        dispatch({
            type: REMOVE_PROFILE_LOADING
        });
        dispatch(setAlert('Error al modificar perfil, inicie sesión e intente de nuevo', 'bad'));
    }
}