//React
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//Components
import Layout from '../hocs/Layout';
import { SwiperBanner } from '../components/aditional/SwiperBanner';
import { ContactForm } from '../components/aditional/ContactForm.js';
import { AnimatedText } from '../components/aditional/AnimatedText.js';
import { WhatsAppButton } from '../components/aditional/WhatsApp'
//Redux
import { connect } from 'react-redux';
//Styles
import '../styles/static-pages.css'
//Icons
import { FaAngleDoubleRight, FaUserCheck, FaUpload, FaMapMarkerAlt, FaQrcode } from "react-icons/fa";
import { FaCircleChevronRight } from 'react-icons/fa6';
import { GoLightBulb } from "react-icons/go";


const Home = ({
	isAuthenticated,
}) => {

	return (
		<Layout>
			<div className='container-home-swiper'>
				<SwiperBanner />
			</div>
			{isAuthenticated && <div className='container-call-to-action'>
				<div className='call-to-action-text'>
					<h3>No pierdas tiempo</h3>
					<p>El uso de códigos QR para vincular la identidad de un objeto, es una herramienta poderosa para proteger y rastrear pertenencias, proporcionando una capa adicional de seguridad y verificación.</p>
					<Link to='/goods/upload-good' style={{textDecoration:'none'}} className='about-link'>Registralos ahora</Link>
				</div>
				<div className='call-to-action-image'>
					<img src={require(`../images/call-banner.webp`)} />
				</div>
			</div>}
			<div className='container-dark'>
				<div className='container container-services'>
					<div className='container-service-title'>
						<h3 className='process-title'>Nuestros servicios</h3>
					</div>
					<div className='container-service-paragraph'>
						<div className='services-items'>
							<FaAngleDoubleRight className='doble-flecha' />
							<div className='services-item-text'>
								<h3 className='color-title'>REGISTRA TUS BIENES</h3>
								<p className='color-text'>Puedes registrar tus bienes desde un telefono celular, computadora, o tablet para evidenciar la titularidad y los derechos asociados.</p>
							</div>
						</div>
						<div className='services-items'>
							<FaAngleDoubleRight className='doble-flecha' />
							<div className='services-item-text'>
								<h3 className='color-title'>CONSERVAMOS TU INFORMACIÓN</h3>
								<p className='color-text'>Generá códigos QR únicos para cada uno de tus bienes, los cuales podrás escanear para acceder rápidamente a la información personal y detallada del bien en nuestro sistema, vinculando cada producto con tu información respaldada en nuestro sitio web.</p>
							</div>
						</div>
						<div className='services-items'>
							<FaAngleDoubleRight className='doble-flecha' />
							<div className='services-item-text'>
								<h3 className='color-title'>IDENTIFICA TUS BIENES</h3>
								<p className='color-text'>La consulta de los bienes a través de códigos QR permite identificar de manera eficaz y rápida a la persona que realizó el registro.</p>
							</div>
						</div>
						{/*<div className='services-items'>
							<FaAngleDoubleRight className='doble-flecha' />
							<div className='services-item-text'>
								<h3 className='color-title'>LISTAMOS TUS BIENES</h3>
								<p className='color-text'>El Registro de Bienes Muebles te proporciona un listado de la información que has cargado sobre tus bienes, vinculada a tu información personal, de manera que pueda ser utilizada en caso de robo, extravío, auditoría y asuntos legales, para contribuir eficazmente.</p>
							</div>
						</div>*/}
						<div className='services-items'>
							<FaAngleDoubleRight className='doble-flecha' />
							<div className='services-item-text'>
								<h3 className='color-title'>NO VALIDAMOS PROPIEDAD</h3>
								<p className='color-text'>Nosotros no validamos la propiedad de los bienes registrados, ya que la propiedad manifestada bajo declaración jurada en el acta de registro por el propietario; nuestro rol se limita a proporcionar identificación a través del código QR.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='container-aboutus'>
				<div className='about-text'>
					<h3>Una herramienta y una idea <GoLightBulb /></h3>
					<p>Aprende cómo nuestro registro de bienes muebles está ayudando a proteger los activos de individuos, empresas y entidades públicas</p>
					<Link to='/about' style={{textDecoration:'none'}} className='about-link'>Sobre Nosotros</Link>
				</div>
			</div>
			<div className='container-home-franchises'>
				<div className='contain-home-franchises'>
					<h3>¿Quieres formar parte de nuestro equipo?</h3>
					<p>Descubre la oportunidad de formar parte de nuestra exitosa red de franquicias de Registro de Bienes Muebles, donde la rentabilidad y el respaldo continuo te llevarán al éxito empresarial</p>
					<p>¡Conoce más sobre cómo unirte y aprovechar esta inversión rentable!</p>
					<Link to='/franchises' className='about-link' style={{textDecoration: 'none'}}>Conoce más</Link>	
				</div>
				<div className='container-franchise-image'>
					<img src={require(`../images/franchise.webp`)} />
				</div>
			</div>
			<div className='container-home-process'>
				<div className='container-process-title'>
					<h3 className='process-title'>Registra tus bienes de manera sencilla</h3>
				</div>
				<div className='contain-home-process'>
					<div className='contain-ecommerce-payment'>
						<div className='payment-content'>
							<div className='payment-back'>
								<img src={require(`../images/login-rbm.webp`)} />
							</div>
							<div className='payment-box'>
								<div className='payment-text'>
									<h4>Crea tu cuenta</h4>
									<p className='text-with-chevron'><FaCircleChevronRight />Da click en ingresar</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Ve a registrarse</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Ingresa tus datos</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Toma una foto del DNI</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Tomate una selfie con el DNI</p>
								</div>
							</div>
						</div>
					</div>
					<div className='contain-ecommerce-payment'>
						<div className='payment-content'>
							<div className='payment-back'>
								<img src={require(`../images/bienes-rbm.webp`)} />
							</div>
							<div className='payment-box'>
								<div className='payment-text'>
									<h4>Registra tu bien</h4>
									<p className='text-with-chevron'><FaCircleChevronRight />Ve al apartado bienes</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Clickea en cargar nuevo bien</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Completa los datos del bien</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Agregar imágenes</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='contain-home-process'>
					<div className='contain-ecommerce-payment'>
						<div className='payment-content'>
							<div className='payment-back'>
								<img src={require(`../images/go-to-rbm.webp`)} />
							</div>
							<div className='payment-box'>
								<div className='payment-text'>
									<h4>Ve a la oficina de RBM</h4>
									<p className='text-with-chevron'><FaCircleChevronRight />Consulta por las fraquicias</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Acercate con tu bien a la mas cercana</p>
								</div>
							</div>
						</div>
					</div>
					<div className='contain-ecommerce-payment'>
						<div className='payment-content'>
							<div className='payment-back'>
								<img src={require(`../images/grabado-rbm.webp`)} />
							</div>
							<div className='payment-box'>
								<div className='payment-text'>
									<h4>Aplica el código QR</h4>
									<p className='text-with-chevron'><FaCircleChevronRight />Aqui grabaremos el código QR</p>
									<p className='text-with-chevron'><FaCircleChevronRight />Abona la tarifa correspondiente</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='container-contact-home'>
				<ContactForm title='Contáctanos' 
				text='Si está interesado en saber más sobre la forma en que trabajamos, tiene una propuesta comercial o está interesado en realizar una compra, nos encantaría saber de usted.' />
			</div>
			<WhatsAppButton />
		</Layout>
	);
};

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
})

export default connect(mapStateToProps, {
}) (Home)