// Authentication
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const REMOVE_AUTH_LOADING = 'REMOVE_AUTH_LOADING';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_FAIL = 'REFRESH_FAIL';	
export const LOGOUT = 'LOGOUT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_CONFIRM_SUCCESS = 'RESET_PASSWORD_CONFIRM_SUCCESS';
export const RESET_PASSWORD_CONFIRM_FAIL = 'RESET_PASSWORD_CONFIRM_FAIL';
export const SEND_ID_IMAGES_SUCCESS = 'SEND_ID_IMAGES_SUCCESS';
export const SEND_ID_IMAGES_FAIL = 'SEND_ID_IMAGES_FAIL';

// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Goods
export const UPLOAD_GOOD_SUCCESS = 'UPLOAD_GOOD_SUCCESS';
export const UPLOAD_GOOD_FAIL = 'UPLOAD_GOOD_FAIL';
export const GET_GOODS_SUCCESS = 'GET_GOODS_SUCCESS';
export const GET_GOODS_FAIL = 'GET_GOODS_FAIL';
export const GET_GOOD_SUCCESS = 'GET_GOOD_SUCCESS';
export const GET_GOOD_FAIL = 'GET_GOOD_FAIL';
export const MODIFY_GOOD_SUCCESS = 'MODIFY_GOOD_SUCCESS';
export const MODIFY_GOOD_FAIL = 'MODIFY_GOOD_FAIL';
export const SET_GOOD_LOADING = 'SET_GOOD_LOADING';
export const REMOVE_GOOD_LOADING = 'REMOVE_GOOD_LOADING';
export const GET_PROFILE_GOOD_SUCCESS = 'GET_PROFILE_GOOD_SUCCESS';
export const GET_PROFILE_GOOD_FAIL = 'GET_PROFILE_GOOD_FAIL';

// User Profile
export const SET_PROFILE_LOADING = 'SET_PROFILE_LOADING';
export const REMOVE_PROFILE_LOADING = 'REMOVE_PROFILE_LOADING';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';

// Payment
export const CREATE_PREFERENCE_SUCCESS = 'CREATE_PREFERENCE_SUCCESS';
export const CREATE_PREFERENCE_FAIL = 'CREATE_PREFERENCE_FAIL';
export const SET_PREFERENCE_LOADING = 'SET_PREFERENCE_LOADING';
export const REMOVE_PREFERENCE_LOADING = 'REMOVE_PREFERENCE_LOADING';

// Costs
export const GET_COSTS_SUCCESS = 'GET_COSTS_SUCCESS';
export const GET_COSTS_FAIL = 'GET_COSTS_FAIL';

// Database Cart
export const ADD_ITEM_SUCCESS = 'ADD_ITEM_SUCCESS';
export const ADD_ITEM_FAIL = 'ADD_ITEM_FAIL';
export const GET_TOTAL_SUCCESS = 'GET_TOTAL_SUCCESS';
export const GET_TOTAL_FAIL = 'GET_TOTAL_FAIL';
export const GET_ITEM_TOTAL_SUCCESS = 'GET_ITEM_TOTAL_SUCCESS';
export const GET_ITEM_TOTAL_FAIL = 'GET_ITEM_TOTAL_FAIL';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_FAIL = 'GET_ITEMS_FAIL';
export const UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_FAIL = 'UPDATE_ITEM_FAIL';
export const REMOVE_ITEM_SUCCESS = 'REMOVE_ITEM_SUCCESS';
export const REMOVE_ITEM_FAIL = 'REMOVE_ITEM_FAIL';
export const EMPTY_CART_SUCCESS = 'EMPTY_CART_SUCCESS';
export const EMPTY_CART_FAIL = 'EMPTY_CART_FAIL';
export const SYNCH_CART_SUCCESS = 'SYNCH_CART_SUCCESS';
export const SYNCH_CART_FAIL = 'SYNCH_CART_FAIL';

// Locale Cart
export const ADD_ITEM = 'ADD_ITEM';
export const GET_TOTAL = 'GET_TOTAL';
export const GET_ITEM_TOTAL = 'GET_ITEM_TOTAL';
export const GET_ITEMS = 'GET_ITEMS';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const EMPTY_CART = 'EMPTY_CART';