import {
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAIL,
    UPDATE_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_FAIL,
    SET_PROFILE_LOADING,
    REMOVE_PROFILE_LOADING,
} from '../actions/types';

const initialState = {
    profile: null,
    profileLoading: false,
    profileModified: false,
};

export default function Profile(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case SET_PROFILE_LOADING:
            return {
                ...state,
                profileLoading: true
            };
        case REMOVE_PROFILE_LOADING:
            return {
                ...state,
                profileLoading: false
            };
        case GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                profile: payload.profile
            }
        case GET_USER_PROFILE_FAIL:
            return {
                ...state
            }
        case UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                profile: payload.profile,
                profileModified: true
            }
        case UPDATE_USER_PROFILE_FAIL:
            return {
                ...state,
                profileModified: false
            }
        default:
            return state
    }
}