import Layout from '../../hocs/Layout';
import { useParams } from 'react-router';
import { useState } from 'react';
import { connect } from 'react-redux';
import { activate } from '../../redux/actions/auth';
import { Navigate } from 'react-router';
import { Oval } from 'react-loader-spinner';


const Activate = ({
	activate, loading
}) => {
	const params = useParams()
	const [activated, setActivate] = useState(false)

	const activate_account = () => {
		const uid = params.uid
		const token = params.token
		activate(uid, token);
		setActivate(true);
	};
	
	if (activated && !loading)
		return <Navigate to='/' />;
	
	return (
		<Layout>
			<div className='container-activate'>
				<div className='form-content'>
					<img className='img-sign' src={require(`../../images/logorbm.png`)} />
					<h3 className='form-title'>Activa tu cuenta</h3>
					{loading ? 
					<button type='button'><Oval
  						visible={true}
						height="20"
						width="20"
						color="#ddd"
						ariaLabel="oval-loading"
						wrapperStyle={{}}
						wrapperClass=""
						secondaryColor="#ddd"
						className='button-form'
						/>
					</button> : 
					<button onClick={activate_account} className='button-form' >Activar</button>}
				</div>
			</div>
		</Layout>
	);
};
const mapStateToProps = state => ({
	loading: state.Auth.loading
})

export default connect(mapStateToProps, {
	activate
}) (Activate)