import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../../styles/components.css';

import { AnimatedText } from './AnimatedText.js';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export const SwiperBanner = () => {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='swiper-banner-home earring'>
                        <AnimatedText text='Registra tu mundo en un solo click' className='animated-title' />
                        <p>A través de códigos QR tendrás toda la información de tus bienes</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='swiper-banner-home necklace'>
                        <AnimatedText text='Registra tu mundo en un solo click' className='animated-title' />
                        <p>Registra todos tus bienes no importa su antiguedad, precio, etc.</p>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='swiper-banner-home bracelet'>
                        <AnimatedText text='Registra tu mundo en un solo click' className='animated-title' />
                        <p>No dudes más, comienza ahora</p>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}