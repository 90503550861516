import React from 'react';
import '../../styles/auth.css';
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

export const ContactForm = ({ title, text }) => {

    const form = useRef();
    const [clicked, setClicked] = useState(false);
    const [sendedEmail, setSendedEmail] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_lps44re', 'template_ksmgzid', form.current, {
                publicKey: 'nfd_phSCzilgjQfDr',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setSendedEmail(true);
                    form.current.reset();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };


    return (
        <form className='container contact-form' ref={form} onSubmit={sendEmail}>
            <div className='title-contact'>
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
            <div className='form-contact'>
                <div className='form-section'>
                    <label>Nombre y Apellido</label>
                    <input type='text' name='user_name' placeholder='Tu nombre' className='input-form' />
                </div>
                <div className='form-section'>
                    <label>Email</label>
                    <input type='email' name='user_email' placeholder='Tu dirección de email' className='input-form' />
                </div>
                <div className='form-section'>
                    <label>Mensaje</label>
                    <textarea name='message' placeholder='Tu mensaje' className='input-form-message'></textarea>
                </div>
                <p className='required-fields'>Todos los campos son obligatorios</p>
                {sendedEmail ? (
                    <p className='submited-form'>Enviado</p>
                ) : (
                    <input type='submit' value='Enviar' className='submit-button' />
                )}
            </div>
        </form>
    );
};