import Layout from '../../hocs/Layout';
import { FaCircle, FaRegCircle } from 'react-icons/fa';
import '../../styles/static-pages.css';
import { useEffect } from 'react';


const LegalAspects =({
})=>{

	useEffect(() => {
      	window.scrollTo(0,0)
    }, [])

	return (
		<Layout>
			<div className='container'>
				<div className='container-policies'>
					<h2>Aspectos Legales</h2>
					<p>Este sitio web opera bajo el marco legal establecido en el territorio correspondiente, garantizando el 
					cumplimiento de las normativas aplicables a la gestión y registro de bienes muebles.</p>
					<p>Nuestra actividad está respaldada por el acta número 20240103334, la cual formaliza 
					nuestras operaciones y asegura la transparencia de nuestros procesos.</p>
					<p>Contamos con el respaldo jurídico del abogado Pedro Frontera, quien nos asesora para garantizar el 
					cumplimiento de todas las obligaciones legales vigentes y ofrecer un servicio seguro y confiable para nuestros 
					usuarios.</p>
					<h3>Contacto</h3>
					<p>Para cualquier consulta relacionada, por favor contacte a nuestro equipo de soporte a través de 
					contacto@registrobienesmuebles.com.ar</p>
				</div>
			</div>
		</Layout>
	)
};

export default LegalAspects;