import Layout from '../hocs/Layout';
import { useState, useEffect } from 'react';
import { get_goods } from "../redux/actions/good";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../styles/goods.css'
import { Navigate } from 'react-router';


const GoodDetail =({
    get_goods,
    goods,
    isAuthenticated
})=>{

	useEffect(() => {
      window.scrollTo(0,0)
        get_goods()
    }, [])

    if(!isAuthenticated)
        return <Navigate to="/" />

	return(
		<Layout>
			<div className='container-good-page'>
				<div className='container'>
					<div className="table-header">
		            		<div>Imagen</div>
		            		<div>Nombre</div>
		            		<div>Marca</div>
		       			</div>
		            {
		            	goods && 
		            	goods !== null &&
		            	goods !== undefined &&
		            	goods.map(good => (
		                <div key={good && good.id} className='table-row'>
		                	<img 
		                		className='good-img'
		                		src={good && good.main_image_thumbnail}
		                		alt=''
		                		/>
		                    <div className='row-container'>
		                    	<Link to={`/goods/good/${good && good.id}`} className='link-to-detail' style={{ textDecoration: 'none'}}>{good && good.name}</Link>
		                    </div>
		                    <div className='row-container'>{good && good.brand}</div>
		                </div>
		            ))}
		        </div>
		        <div className='link-to-upload'>
		        	<p>Presione el nombre del bien al que quiera acceder</p>
		        	<Link to='/goods/upload-good' style={{textDecoration:'none'}} className='good-link'>Cargar nuevo bien</Link>
		        </div>
	        </div>
		</Layout>
	)	
};

const mapStateToProps = state => ({
    goods: state.Good.goods,
    isAuthenticated: state.Auth.isAuthenticated
})

export default connect(mapStateToProps, {
    get_goods
}) (GoodDetail)