import Layout from '../../hocs/Layout';
import { connect } from 'react-redux';
import { remove_item, update_item, get_items, get_total, get_item_total } from "../../redux/actions/cart";
import { useEffect, useState } from 'react';
import CheckoutItem from '../../components/cart/CheckoutItem';
import { Link } from 'react-router-dom';
import '../../styles/cart.css';
import { setAlert } from '../../redux/actions/alert';
import { create_preference } from "../../redux/actions/payment";
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import { Oval } from 'react-loader-spinner';
import { Navigate } from 'react-router';


const Checkout = ({
	get_items,
	items,
	get_total,
	get_item_total,
	amount,
	total_items,
	isAuthenticated,
	remove_item,
	update_item,
	setAlert,
	create_preference,
	preference,
	payLoading,
}) => {

	initMercadoPago("APP_USR-92e368e2-5737-40d6-9cf8-a91b9e94b974", {locale: "es-AR"});

	const [render, setRender] = useState(false);
	const [totalQuantity, setTotalQuantity] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);
	const [variableIva, setVariableIva] = useState(0);
	const [isFranchiseChecked, setIsFranchiseChecked] = useState(false);
	const [finalAmount, setFinalAmount] = useState(0);
	const [preferenceId, setPreferenceId] = useState('');
	const iva = 21.00;

    useEffect(() => {
        window.scrollTo(0, 0);
        get_items()
        get_total()
        get_item_total()
    }, [render])

    const calculateTotalQuantity = () => {
    	let total = 0

		if (items && items !== null && items !== undefined && items.length > 0) { 
        	items.forEach(item => {
            	total += parseInt(item.count, 10);
        	})
    	}
        setTotalQuantity(total);
    };

    const calculateIVA = () => {
        if (amount) {
            if (isFranchiseChecked) {
                setVariableIva((amount / 3.33 * iva) / 100);
            } else {
                setVariableIva((amount * iva) / 100);
            }
        }
    };

	const calculateTotal = () => {
        if (amount) {
            if (isFranchiseChecked) {
                setTotalPrice((amount / 3.33) + variableIva);
            } else {
                setTotalPrice(amount + variableIva);
            }
        }
    };

    const calculateFinalAmount = () => {
        if (amount) {
            if (isFranchiseChecked) {
                setFinalAmount(amount / 3.33);
            } else {
                setFinalAmount(amount);
            }
        }
    };

    useEffect(() => {
        calculateTotalQuantity();
        calculateIVA();
        calculateTotal();
        calculateFinalAmount();
    }, [amount, variableIva, isFranchiseChecked])

    useEffect(() => {
		if (preference) {
			setPreferenceId(preference.id)
		}
	})

	const handleConfirm = async () => {
		if (items){
			await create_preference(items, totalQuantity, totalPrice, isFranchiseChecked)
		}
	};

	const handleCheckboxChange = () => {
        setIsFranchiseChecked(!isFranchiseChecked);
    };

    const showItems = () => {
    	return (
    		<div>
				{
					items &&
					items !== null &&
					items !== undefined &&
					items.length !== 0 &&
					items.map((item, index) => {
						let count = item.count;

						return (
							<div key={index}>
								<CheckoutItem
									item={item}
									count={count}
									render={render}
									setRender={setRender}
								/>
							</div>
						)
					})
				}
			</div>
    	)
    };

    if(!isAuthenticated)
        return <Navigate to="/" />

	return (
		<Layout>
			{
				items &&
				items !== null &&
				items !== undefined &&
				items.length !== 0 ? (
					<div className='container-cart'>
						<div className='container-cart-title'>
							<h2>Códigos a grabar ({total_items && total_items})</h2>
						</div>
						<div className='container-cart-info'>
							<div>
								{showItems()}
							</div>
							<div className='checkout-items'>
								<h3>Resumen del pedido</h3>
								<div className='checkout-item'>
									<p>Cantidad</p>
									<p>{totalQuantity}</p>
								</div>
								<div className='checkout-item'>
									<p>Subtotal</p>
									<p>$ {finalAmount.toFixed(2)}</p>
								</div>
								<div className='checkout-item'>
									<p>IVA</p>
									<p>$ {variableIva}</p>
								</div>
								<div className='checkout-item'>
									<p>Pago a franquicia</p>
									<input type='checkbox' onChange={handleCheckboxChange} />
								</div>
								<div className='checkout-item'>
									<p>Total</p>
									<p>$ {totalPrice}</p>
								</div>
								{
									preferenceId ? (
										<Wallet initialization={{ preferenceId: preferenceId }} />
									) : (
										payLoading ? (
											<button type='button' className='confirm-button'><Oval
							  					visible={true}
												height="25"
												width="25"
												color="#ddd"
												ariaLabel="oval-loading"
												wrapperStyle={{}}
												wrapperClass=""
												secondaryColor="#ddd"
												/>
											</button>
										) : (
											<button onClick={handleConfirm} className='checkout-button'>Confirmar</button>
										)
									)
								}
								<div className='checkout-item'>
									<p className='required-fields'>Marcar la casilla unicamente cuando el grabado sea realizado en una franquicia y no en una oficina del Registro de Bienes Muebles</p>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div>
						<p>No hay productos en tu carrito</p>
						<Link to='/goods' style={{textDecoration: 'none'}}>Volver a tus bienes</Link>
					</div>
				)
			}
		</Layout>
	)
}

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	items: state.Cart.items,
	amount: state.Cart.amount,
	total_items: state.Cart.total_items,
	preference: state.Payment.preference,
	payLoading: state.Payment.payLoading,
})

export default connect(mapStateToProps, {
	get_items,
	get_total,
	get_item_total,
	remove_item,
	update_item,
	setAlert,
	create_preference,
}) (Checkout)