import { connect } from 'react-redux';
import Layout from '../../hocs/Layout';
import { update_user_profile } from '../../redux/actions/profile';
import { get_user_profile } from '../../redux/actions/profile';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/profile.css';
import { FaArrowLeft } from 'react-icons/fa';
import { Navigate } from 'react-router';
import { IoMdClose } from 'react-icons/io';
import { Oval } from 'react-loader-spinner';
import imageCompression from 'browser-image-compression';


const EditProfile =({
		update_user_profile,
		get_user_profile,
		profile,
		isAuthenticated,
		profileLoading,
		profileModified
})=>{

	useEffect(() => {
      	window.scrollTo(0,0)
        get_user_profile()
    }, [])

	const [redirect, setRedirect] = useState(false)
	const [formData, setFormData] = useState({
        address: '',
        city: '',
        state_province_region: '',
        phone: '',
        country_region: ''
    });

    const photoInputRef = useRef(null);
    const [photo, setPhoto] = useState('');
	const [postPhoto, setPostPhoto] = useState(null);
	const [error, setError] = useState('');

    useEffect(() => {
        if (profile) {
            setFormData({
                address: profile.address || '',
                city: profile.city || '',
                state_province_region: profile.state_province_region || '',
                phone: profile.phone || '',
                country_region: profile.country_region || ''
                
            });
            setPhoto(profile.photo)
        }
    }, [profile]);

    const handleChange = (e) => {
        const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value
            });
    };

    const loadImage = async (event, setImage, inputName) => {
        const file = event.target.files[0];
        const options = {
        	maxSizeMB: 1,
        	maxWidthOrHeight: 1920,
        	useWebWorker: true,
        }
        try {

        	if (file) {
            	const compressedFile = await imageCompression(file, options);

            	const compressedImageFile = new File([compressedFile], file.name, {
	                type: file.type,
	                lastModified: file.lastModified,
	            });

	        	if (inputName === 'photo') {
	        		setPostPhoto({
	        			image: event.target.files,
	        		});
	        	}

        		const reader = new FileReader();
        		reader.onload = () => {
            	setImage({
		                file: compressedImageFile,
		                previewURL: reader.result
		            });
        		};

		        reader.onerror = () => {
		            setError('Error al cargar la imagen. Inténtalo de nuevo más tarde.');
		        };

                reader.readAsDataURL(compressedImageFile);
            }
        } catch (error) {
            setError('Error al cargar la imagen. Inténtalo de nuevo más tarde.');
        }
    };

    const handleDelete = (imageKey, setImage, setPostImage, inputRef) => {
        setImage(null);
        setPostImage(null);
        inputRef.current.value = '';
    }

    const onSubmit = async (e) =>{
    	e.preventDefault();
    	if (postPhoto) {
	        const imageData = {
	            photo: postPhoto,
	        };
	    	await update_user_profile(formData, imageData);
	    	setRedirect(true);
    	}
  	};

  	if(profileModified && redirect) {
  		return <Navigate to="/profile" />
  	}

  	if(!isAuthenticated)
        return <Navigate to="/" />

	return (
		<Layout>
			<div className='container-form-edit'>
				<form className='form-content' onSubmit={e => onSubmit(e)}>
					<Link to='/profile' className='return-button' style={{textDecoration: 'none'}}><FaArrowLeft />Regresar</Link>
					<img className='img-sign' src={require(`../../images/logorbm.png`)} />
						<h3 className='form-title'>Modificar perfil</h3>
					<div className='form-section-edit'>
						<div className='profile-preview'>
							<div className='profile-image-container'>
		                            {photo && <div className='preview-image-profile'>
		                                <img src={photo.previewURL || photo} alt="Preview 1" />
		                                <button type="button" className='delete-button' onClick={() => handleDelete('photo', setPhoto, setPostPhoto, photoInputRef)}><IoMdClose /></button>
		                            </div>}
									{!photo && <div className="profile-placeholder">Imagen de perfil</div>}
								<input className='input-profile' type="file" name='photo' ref={photoInputRef} onChange={(e) => loadImage(e, setPhoto, 'photo')} />
							</div>
						</div>
				        <input
				            type='text'
				            name='address'
				            value={formData.address}
				            onChange={handleChange}
				            placeholder='Dirección'
				            className='input-form'
				        />
				        </div>
				        <div className='form-section-edit'>
				        <input
				            type='text'
				            name='city'
				            value={formData.city}
				            onChange={handleChange}
				            placeholder='Ciudad'
				            className='input-form'
				        />
				        </div>
				        <div className='form-section-edit'>
						<input
				            type='text'
				            name='state_province_region'
				            value={formData.state_province_region}
				            onChange={handleChange}
				            placeholder='Estado o provincia'
				            className='input-form'
				        />
				        </div>
				        <div className='form-section-edit'>
				        <input
				            type='text'
				            name='phone'
				            value={formData.phone}
				            onChange={handleChange}
				            placeholder='Teléfono'
				            className='input-form'
				        />
				        </div>
				        <div className='form-section-edit'>
				        <input
				            type='text'
				            name='country_region'
				            value={formData.country_region}
				            onChange={handleChange}
				            placeholder='País'
				            className='input-form'
				        />
			        </div>
			        {profileLoading ? 
						<button type='button' className='button-form'><Oval
			  				visible={true}
							height="25"
							width="25"
							color="#ddd"
							ariaLabel="oval-loading"
							wrapperStyle={{}}
							wrapperClass=""
							secondaryColor="#ddd"
							/>
						</button> : 
			        	<button className='button-form' type='submit'>Modificar</button>
			        }
		        </form>
	        </div>
		</Layout>
	)
};

const mapStateToProps = state => ({
	profile: state.Profile.profile,
	isAuthenticated: state.Auth.isAuthenticated,
	profileLoading: state.Profile.profileLoading,
	profileModified: state.Profile.profileModified
})

export default connect(mapStateToProps, {
	update_user_profile,
	get_user_profile
}) (EditProfile)