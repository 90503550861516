import Layout from '../../hocs/Layout';
import { useState, useEffect } from 'react';
import '../../styles/profile.css';
import { get_user_profile } from '../../redux/actions/profile';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router';


const Profile =({
    get_user_profile,
    profile,
    isAuthenticated
})=>{

	useEffect(() => {
      	window.scrollTo(0,0)
        get_user_profile()
    }, [])

	const [activeTab, setActiveTab] = useState('personal');

  	const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    if(!isAuthenticated)
        return <Navigate to="/" />

  	return (
  		<Layout>
	  		<div className='background-profile'>
		    	<div className='profile-div'>
		      		<div className='container-profile'>
		      			<div className='image-profile'>
		       				<span>{profile && profile.full_name}</span>
		       				<img src={profile && profile.photo} alt='' />
		      			</div>
		      			<div className='container-profile-forms'>
		      				<div className='tabs'>
	                    		<div className={activeTab === 'personal' ? 'tab active-tab' : 'tab'} onClick={() => handleTabClick('personal')}>Datos Personales</div>
	                    		<div className={activeTab === 'security' ? 'tab active-tab' : 'tab'} onClick={() => handleTabClick('security')}>Seguridad</div>
	                		</div>
		                	<div className='container-tabs'>
			      				{activeTab === 'personal' && (
		                        	<div className='personal-form'>
		                            	<div className='form-label'>
						        			<label>Nombre:</label>
						        			<span>{profile && profile.full_name}</span>
						    			</div>
									    <div className='form-label'>
									        <label>Email:</label>
									        <span>{profile && profile.email}</span>
									    </div>
									    <div className='form-label'>
									        <label>Dirección:</label>
									        <span>{profile && profile.address}</span>
									    </div>
									    <div className='form-label'>
									        <label>Teléfono:</label>
									        <span>{profile && profile.phone}</span>
									    </div>
									    <div className='form-label'>
									        <label>Ciudad:</label>
									        <span>{profile && profile.city}</span>
									    </div>
									    <div className='form-label'>
									        <label>Provincia o Estado:</label>
									        <span>{profile && profile.state_province_region}</span>
									    </div>
									    <div className='form-label'>
									        <label>País:</label>
									        <span>{profile && profile.country_region}</span>
									    </div>
		                        	</div>
		                    	)}
						    	{activeTab === 'security' && (
		                        	<div className='personal-form'>
		                            	<div className='form-label-security'>
						        			<label>Email de recuperación:</label>
						        			<span>{profile && profile.email}</span>
						    			</div>
						    			<div className='form-label-security'>
						        			<label>Teléfono de recuperación:</label>
						        			<span>{profile && profile.phone}</span>
						    			</div>
		                            	<div>
	                                    	<Link to='/reset_password' className='change-password-button' style={{textDecoration: 'none'}}>Cambiar contraseña</Link> 
	                                	</div>
		                        	</div>
		                    	)}
			        		</div>
	                	</div>
					</div>
					<Link to='/profile/edit' className='edit-button' style={{textDecoration: 'none'}}>Editar perfil</Link>
		    	</div>
	    	</div>
    	</Layout>
  	);
};

const mapStateToProps = state => ({
    profile: state.Profile.profile,
    isAuthenticated: state.Auth.isAuthenticated
})

export default connect(mapStateToProps, {
    get_user_profile
}) (Profile)