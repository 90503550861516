import { Link } from 'react-router-dom';
import '../../styles/navigation.css';
//Icons
import { FaChevronRight } from "react-icons/fa6";
import { FaRegEnvelope, FaPhoneAlt, FaFacebook, FaInstagram } from "react-icons/fa";


const Footer = () => {
	return (
		<div className='container-footer'>
			<div className='footer-dividers'>
				<div className='container-footer-contact'>
					<h4 className='contact-title-footer'>Contáctanos</h4>
					<p className='contact-text'><FaRegEnvelope />contacto@registrobienesmuebles.com.ar</p>
					<p className='contact-text'><FaPhoneAlt />+54 9 3574-461111</p>
				</div>
				<div className='footer-social-network'>
					<h4 className='contact-title-footer'>Nuestras Redes</h4>
					<div className='social-media-footer'>
						<Link to='https://www.facebook.com/profile.php?id=61560476252809' className='footer-social-facebook'><FaFacebook /></Link>
						<Link to='https://www.instagram.com/registro_de_bienes_muebles?igsh=Y2dzZWh5Nmg5emtu&utm_source=qr' className='footer-social-instagram'><FaInstagram /></Link>
					</div>
				</div>
				<div className='container-privacy'>
					<h4 className='contact-title-footer'>Links Utiles</h4>
					<Link to='/' style={{textDecoration:'none'}} className='privacy-links'>Inicio</Link>
					<Link to='/about' style={{textDecoration:'none'}} className='privacy-links'>Sobre Nosotros</Link>
					<Link to='/contact' style={{textDecoration:'none'}} className='privacy-links'>Contacto</Link>
				</div>
				<div className='container-privacy'>
					<h4 className='contact-title-footer'>Políticas</h4>
					<Link to='/legal-aspects' style={{textDecoration:'none'}} className='privacy-links'>Aspectos Legales</Link>
					<Link to='/cookies-policies' style={{textDecoration:'none'}} className='privacy-links'>Preguntas Frecuentes</Link>
					<Link to='/cookies-policies' style={{textDecoration:'none'}} className='privacy-links'>Política de Cookies</Link>
					<Link to='/billing-policies' style={{textDecoration:'none'}} className='privacy-links'>Política de Cobros</Link>
					<Link to='/privacy-policies' style={{textDecoration:'none'}} className='privacy-links'>Política de Privacidad</Link>
					<Link to='/terms-and-conditions' style={{textDecoration:'none'}} className='privacy-links'>Términos y Condiciones</Link>
				</div>
				<div className='container-newsletter'>
					<h4 className='contact-title-footer'>Suscribete a nuestra Newsletter</h4>
					<form className='form-newsletter'>
						<input type='text' className='newsletter-input' placeholder='Email'/>
						<button type='submit' className='newsletter-button'><FaChevronRight className='newsletter-icon' /></button>
					</form>
				</div>
			</div>
			<div className='container-footer-rights'>
				<span className='container-rights'>
					RBM {new Date().getFullYear()} &copy; Todos los Derechos Reservados
				</span>
				<p>Elaborado por <Link to='https://k-ching.com' className='footer-links' style={{textDecoration: 'none'}}>K-Ching</Link></p>
			</div>
		</div>
	)
}

export default Footer;