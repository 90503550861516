import {
    SET_PREFERENCE_LOADING,
    REMOVE_PREFERENCE_LOADING,
	CREATE_PREFERENCE_SUCCESS,
    CREATE_PREFERENCE_FAIL,
} from './types'
import { setAlert } from './alert';
import axios from 'axios'


export const create_preference = (items, quantity, price, pay_to_franchise) => async dispatch => {
    dispatch({
        type: SET_PREFERENCE_LOADING
    });

    if(localStorage.getItem('access')){
        const config = {
            headers: {
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        console.log(price)
        
        const body = {
            good_ids: items.map(item => item.good.id),
            quantity,
            price,
            pay_to_franchise,
        };

        console.log(body)

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/payment/mercadopago/preference`, body, config);

            if (res.status === 200) {
                dispatch({
                    type: CREATE_PREFERENCE_SUCCESS,
                    payload: res.data
                });
                dispatch(setAlert('Preferencia de pago creada correctamente', 'good'))
            } else {
                dispatch({
                    type: CREATE_PREFERENCE_FAIL
                });
                dispatch(setAlert('Ocurrió un error, intente más tarde', 'bad'))
            }
            dispatch({
                type: REMOVE_PREFERENCE_LOADING
            });
        } catch(err) {
            dispatch({
                type: CREATE_PREFERENCE_FAIL
            });
            dispatch({
                type: REMOVE_PREFERENCE_LOADING
            });
            dispatch(setAlert('Ocurrió un error de servidor, intente más tarde', 'bad'))
        }
    } else {
        dispatch({
            type: CREATE_PREFERENCE_FAIL
        });
        dispatch({
            type: REMOVE_PREFERENCE_LOADING
        });
        dispatch(setAlert('Error de autenticación, inicia sesión antes de continuar', 'bad'))
    }
};