import Layout from '../../hocs/Layout';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { signup } from '../../redux/actions/auth';
import { Navigate } from 'react-router';
import { Oval } from 'react-loader-spinner';
import '../../styles/auth.css'
import { Link } from 'react-router-dom';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";


const Signup = ({
 	signup,
 	loading,
 	newUser
}) => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	const [submitted, setSubmitted] = useState(false);
	const [passwordType, setPasswordType] = useState('password');
	const [iconShow, setIconShow] = useState(false);
	const [rePasswordType, setRePasswordType] = useState('password');
	const [reIconShow, setReIconShow] = useState(false);
	const [acceptTerms, setAcceptTerms] = useState(false);
	const [formData, setFormData] = useState({
		full_name: '',
		card_id: '',
		email: '',
		password: '',
		re_password: ''
	});

	const {
		full_name,
		card_id,
		email,
		password,
		re_password
	} = formData;

	const onChange = e => setFormData({...formData, [e.target.name]: e.target.value });

	const onSubmit = async e =>{
    	e.preventDefault();
    	await signup(full_name, card_id, email, password, re_password);
    	setSubmitted(true);
  	};

  	const handleShowPassword = () => {
  		if (passwordType === 'password') {
  			setPasswordType('text')
  			setIconShow(!iconShow)
  		}
  		else if (passwordType === 'text') {
  			setPasswordType('password')
  			setIconShow(!iconShow)
  		}
  	}

  	const handleShowRePassword = () => {
  		if (rePasswordType === 'password') {
  			setRePasswordType('text')
  			setReIconShow(!reIconShow)
  		}
  		else if (rePasswordType === 'text') {
  			setRePasswordType('password')
  			setReIconShow(!reIconShow)
  		}	
  	}

  	if (submitted && newUser && newUser.id) {
	    return <Navigate to={`/signup/${newUser.id}/upload-card`} />;
	}

	const handleCheckboxChange = () => {
        setAcceptTerms(!acceptTerms);
    };

	return (
		<Layout>
			<div className='container-form-auth'>
				<form className='form-content' onSubmit={e => onSubmit(e)}>
					<img className='img-sign' src={require(`../../images/logorbm.png`)} />
					<h3 className='form-title'>Registro</h3>
					<div className='form-section-auth'>
						<label htmlFor='first_name'>Nombre y Apellido</label>
						<input
						name='full_name'
						value={full_name}
						onChange={e => onChange(e)}
						type='text'
						required
						className='input-form' />
					</div>
					<div className='form-section-auth'>
						<label htmlFor='last_name'>DNI, Nº de CUIT o CUIL</label>
						<input
						name='card_id'
						value={card_id}
						onChange={e => onChange(e)}
						type='text'
						required
						className='input-form' />
					</div>
					<div className='form-section-auth'>
						<label htmlFor='email'>Correo Electrónico</label>
						<input
						name='email'
						value={email}
						onChange={e => onChange(e)}
						type='email'
						required
						className='input-form' />
					</div>
					<div className='form-section-auth password-button'>
						<label htmlFor='password'>Contraseña</label>
						<input
						name='password'
						value={password}
						onChange={e => onChange(e)}
						type={passwordType}
						required
						className='input-form' />
						{iconShow ? (
							<FaRegEyeSlash onClick={handleShowPassword} className='password-show-hide' />
						) : (
							<FaRegEye onClick={handleShowPassword} className='password-show-hide' />
						)}
					</div>
					<div className='form-section-auth password-button'>
						<label htmlFor='re_password'>Repita la contraseña</label>
						<input
						name='re_password'
						value={re_password}
						onChange={e => onChange(e)}
						type={rePasswordType}
						required
						className='input-form' />
						{reIconShow ? (
							<FaRegEyeSlash onClick={handleShowRePassword} className='password-show-hide' />
						) : (
							<FaRegEye onClick={handleShowRePassword} className='password-show-hide' />
						)}
					</div>
					<div className='required-gap'>
						<input type='checkbox' onChange={handleCheckboxChange} />
						<p className='required-fields'>He leido y acepto la <Link to='/privacy-policies' className='required-fields-link' style={{textDecoration:'none'}}>Política de privacidad</Link> y <Link to='/terms-and-conditions' className='required-fields-link' style={{textDecoration:'none'}}>Términos de uso</Link></p>
					</div>
					{loading ? (
						<button type='button' className='button-form'><Oval
	  						visible={true}
							height="20"
							width="20"
							color="#fff"
							ariaLabel="oval-loading"
							wrapperStyle={{}}
							wrapperClass=""
							secondaryColor="#ddd"
							/>
						</button> 
					) : (acceptTerms ? (
							<button type='submit' className='button-form'>Registrarse</button>
						) : (
							<p className='not-button-form'>Registrarse</p>)
					)}
				</form>
			</div>
		</Layout>
	);
}

const mapStateToProps = state => ({
	loading: state.Auth.loading,
	newUser: state.Auth.newUser
})

export default connect(mapStateToProps, {
	signup
}) (Signup)