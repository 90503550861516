import Layout from '../../hocs/Layout';
import { AnimatedText } from '../../components/aditional/AnimatedText.js';
import '../../styles/franchises.css';
import { ParallaxSection } from '../../components/aditional/ParallaxSection.js';
import { useEffect, useState } from 'react';
import { WhatsAppButton } from '../../components/aditional/WhatsApp';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { MdHealthAndSafety, MdTimelapse } from "react-icons/md";
import { GiProfit } from "react-icons/gi";
import { FAQFranchise } from '../../components/aditional/Faq';


const Franchises = () => {

	useEffect(() => {
      window.scrollTo(0,0)
    }, [])
    
	return (
		<Layout>
			<div className='container-background-work'>
				<div className='container container-franchise-work'>
					<div className='contain-franchise-work'>
						<h2>Trabaja con nosotros</h2>
						<div className='container-work-image'>
							<img src={require(`../../images/logorbm.png`)} />
							<p className='title-franchise-rbm'>Registro<br/> de Bienes<br/> Muebles</p>
						</div>
						<p className='welcome-text'>Bienvenidos y gracias por su interés en unirse a nuestra red de franquicias de Registro de Bienes Muebles! Estamos entusiasmados de 
						presentarles una oportunidad de negocio rentable y sostenible.</p>
					</div>
				</div>
			</div>
			<div className='container-franchise-benefits'>
				<h2>Beneficios de la Franquicia</h2>
				<div className='contain-franchise-benefits'>
					<div className='boxes-benefits'>
						<GiProfit className='benefits-icons' />
						<h4>Alto Margen de Ganancia</h4>
						<p>Como franquiciado, obtendrás un 30% del costo del grabado, asegurando una ganancia significativa en cada operación. Un beneficio mayor 
						que cualquier otra franquicia en el mercado.</p>
					</div>
					<div className='boxes-benefits'>
						<MdTimelapse className='benefits-icons' />
						<h4>Inversión Recuperable Rápidamente</h4>
						<p>Con una inversión inicial de aproximadamente 1 millón de pesos, puedes recuperar tu inversión en tan solo dos meses.</p>
					</div>
					<div className='boxes-benefits'>
						<MdHealthAndSafety className='benefits-icons' />
						<h4>Vida Útil de Equipos</h4>
						<p>Nuestras máquinas láser tienen una vida útil promedio de 10,000 a 15,000 horas, garantizando un largo periodo de operación eficiente.</p>
					</div>
				</div>
			</div>
			<div className='container-franchise-profit'>
				<h2>Proyección de Rentabilidad</h2>
				<div className='container-profit-image'>
					<img src={require(`../../images/franchise-profit.webp`)} />
				</div>
			</div>
			<div className='container-franchise-compromise'>
				<div className='contain-franchise-compromise'>
					<h4>Contrato de Franquicia</h4>
					<p>Para unirte a nuestra red de franquicias, se requiere la firma de un contrato donde se establecen las condiciones y obligaciones 
					de ambas partes.</p>
				</div>
				<div className='contain-franchise-compromise'>
					<h4>Revisión de Solicitud</h4>
					<p>Cada solicitud será revisada minuciosamente por nuestro equipo. Nos reservamos el derecho de aceptar o rechazar cualquier solicitud, 
					garantizando así el cumplimiento de nuestros estándares de calidad y servicio.</p>
				</div>
			</div>
			<div className='container-franchise-select'>
				<h2>¿Por Qué Elegirnos?</h2>
				<div className='container-grid-animation'>
					<div className='animation-content'>
						<div className='animation-face face-1'>
							<div className='content'>
								<h4>Modelo de negocio probado</h4>
							</div>
						</div>
						<div className='animation-face face-2'>
							<div className='content'>
								<p>Contamos con un modelo de negocio que ha demostrado ser exitoso y rentable</p>
							</div>
						</div>
					</div>
					<div className='animation-content'>
						<div className='animation-face face-1'>
							<div className='content'>
								<h4>Soporte Continuo</h4>
							</div>
						</div>
						<div className='animation-face face-2'>
							<div className='content'>
								<p>Ofrecemos apoyo constante en la capacitación, marketing y operación diaria para asegurar tu éxito</p>
							</div>
						</div>
					</div>
					<div className='animation-content'>
						<div className='animation-face face-1'>
							<div className='content'>
								<h4>Innovación y Tecnología</h4>
							</div>
						</div>
						<div className='animation-face face-2'>
							<div className='content'>
								<p>Utilizamos tecnología de punta en el grabado láser, garantizando precisión y durabilidad en nuestros servicios</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='container container-faqs'>
					<h3 className='faqs-title'>Preguntas Frecuentes</h3>
					<FAQFranchise />
				</div>
			<WhatsAppButton />
		</Layout>
	);
};

export default Franchises;