import { combineReducers } from 'redux';
import Auth from './auth';
import Alert from './alert';
import Good from './good';
import Profile from './profile';
import Payment from './payment';
import Costs from './costs';
import Cart from './cart';


export default combineReducers({
	Auth,
	Alert,
	Good,
	Profile,
	Payment,
	Costs,
	Cart,
})