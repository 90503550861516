//React
import React from 'react';
//Components
import { Link } from 'react-router-dom';
//Styles
import '../../styles/components.css';
//Icons
import { FaWhatsapp } from "react-icons/fa";


export const WhatsAppButton = () => {
	return (
		<Link to='https://wa.me/+543574461111?text=Hola,%20me%20interesa%20recibir%20más%20información%20acerca%20del%20Registro%20de%20Bienes%20Muebles.' className='whatsapp-button' style={{textDecoration: 'none'}}>
			<div className='whatsapp-icon'><FaWhatsapp /></div>
			<p>Estamos en línea</p>
		</Link>
	);
};