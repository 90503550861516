import { NavLink, Link } from 'react-router-dom';
import Alert from '../../components/alert';
import { connect } from 'react-redux';
import { Fragment, useState, useEffect } from 'react';
import { Menu } from '@headlessui/react'
import { logout, check_authenticated } from '../../redux/actions/auth';
import { Navigate } from 'react-router';
import '../../styles/navigation.css';
import { FaUser, FaBars, FaShoppingCart } from "react-icons/fa";
import { get_item_total } from '../../redux/actions/cart';

const CustomLink = ({to, title, className=''}) => {
	return (
		<NavLink to={to} className={`${className} ${isActive => isActive ? 'active' : ''}`} style={{textDecoration:'none'}}>
			{title}
			<span className='span-bar'></span>
		</NavLink>
	);
};

function Navbar({
	isAuthenticated,
	user,
	logout,
	get_item_total,
	total_items,
	check_authenticated,
}) {

	useEffect(() => {
		check_authenticated();
    }, []);

	const [redirect, setRedirect] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);

	useEffect(() => {
		if (isAuthenticated) {
			get_item_total()
			check_authenticated()
		}
    }, [isAuthenticated, total_items]);

	const logoutHandler = () => {
		logout();
		setRedirect(true);
	}

	const authLinks = (
		<Menu>
	      	<Menu.Button className='container-user-button'><FaUser /></Menu.Button>
	      	<Menu.Items className='menu-navbar'>
		        <Menu.Item className='submenu-active'>
		          {({ active }) => (
		            <Link
		              className='desplegable-menu'
		              to='/profile'
		              style={{textDecoration: 'none'}}
		            >
		              Perfil
		            </Link>
		          )}
		        </Menu.Item>
		        <Menu.Item className='submenu-active'>
		          <button onClick={logoutHandler} className="signout-button">Cerrar Sesión</button>
		        </Menu.Item>
	      	</Menu.Items>
	    </Menu>
	);

	const guestLinks = (
		<Fragment >
			<div className='navlink-social'>
				<CustomLink to='/login' className='navlink-item' title='Ingresar' />
			</div>
		</Fragment>
	);

	const toggleMenu = () => {
    	setMenuOpen(!menuOpen);
  	};

	if (redirect) {
    	<Navigate to='/' />;
  	};

	return (
		<>
			<div className='container-navbar'>
				<div className='container-logo'>
					<Link to= '/'>
						<img className = 'navbar-logo'
						src={require(`../../images/logo-main-rbm.png`)} />
					</Link>
				</div>
				<div className={`container-navlinks ${menuOpen ? 'menu-active' : ''}`}>
					<CustomLink to='/' title='Inicio' className='navlink-item' />
					<CustomLink to='/about' title='Sobre Nosotros' className='navlink-item' />
					{isAuthenticated && <CustomLink to='/goods' title='Bienes' className='navlink-item' />}
					<CustomLink to='/contact' title='Contacto' className='navlink-item' />
					<div className='navlink-social'>
					{isAuthenticated && <Link to='/cart' style={{textDecoration: 'none'}} className='container-user-button'><FaShoppingCart /><span>{total_items}</span></Link>}
						{
							isAuthenticated ? authLinks : guestLinks
						}
					</div>
					
				</div>
				<button className='toggle-menu' id='menu-toggle' aria-label='Toggle Menu' onClick={toggleMenu}>
					<FaBars />
				</button>
			</div>
			<Alert />
		</>
	);
};

const mapStateToProps = state => ({
	isAuthenticated: state.Auth.isAuthenticated,
	user: state.Auth.user,
	total_items: state.Cart.total_items
})

export default connect(mapStateToProps, {
	logout,
	get_item_total,
	check_authenticated
}) (Navbar)