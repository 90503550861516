import { jsPDF } from 'jspdf';
import { connect } from 'react-redux';
import { get_user_profile } from '../../redux/actions/profile';
import { useEffect, useState } from 'react';
import Layout from '../../hocs/Layout';
import { useParams } from 'react-router';
import '../../styles/static-pages.css';
import { get_good } from "../../redux/actions/good";


const RegisterActGood = ({
	get_user_profile,
    profile, 
    user,
    get_good,
    good,
})=>{

	const params = useParams()
    const goodId = params.goodId

	useEffect(() => {
      	window.scrollTo(0,0)
      	get_user_profile()
      	get_good(goodId)
    }, [])

    const [fullName, setFullName] = useState(null);
    const [address, setAddress] = useState(null);
    const [cardId, setCardId] = useState(null);
    const [phone, setPhone] = useState(null);
    const [name, setName] = useState(null);
    const [brand, setBrand] = useState(null);
    const [model, setModel] = useState(null);
    const [description, setDescription] = useState(null);

    useEffect(() => {
        if (profile) {
            setFullName(profile.full_name);
            setAddress(profile.address);
            setPhone(profile.phone);
        }
        if (good) {
        	setName(good.name);
			setBrand(good.brand);
			setModel(good.model);
			setDescription(good.description);
        }
    }, [profile, good]);

    useEffect(() => {
        if (user) {
            setCardId(user.card_id);
        }
    }, [user]);

    const currentDate = new Date;

    const day = currentDate.getDate();
    const monthNames = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    const month = monthNames[currentDate.getMonth()];

    const GeneratePDF = () => {
	    const doc = new jsPDF();

	    doc.setFontSize(18);
	    doc.setFont('helvetica', 'bold');
	    doc.text('ACTA DE REGISTRO DE BIENES MUEBLES', 40, 20);
	    doc.setFontSize(12);
	    doc.setFont('helvetica', 'normal');
	    doc.text(`En la localidad de Río Primero, a los ${day} día(s) del mes de ${month} del año dos mil veinticuatro,`, 20, 40);
	    doc.text('en el sistema  RBM, REGISTRO DE BIENES MUEBLES  lleva  adelante y  a los efectos', 20, 50);
	    doc.text(`legales  posteriores  se  le  DESIGNA  al  Sr/a. ${fullName}  Domicilio `, 20, 60);
	    doc.text(`${address} DNI  Nº ${cardId}  teléfono Nº ${phone}  los códigos   `, 20, 70);
	    doc.text(`QR  suplidos  por el  sistema.  Quien  suscribe declara que tiene  interés  legítimo,  ser `, 20, 80);
	    doc.text('propietario de los bienes  muebles que solicita la identificación mediante el sistema QR,', 20, 90);
	    doc.text('Dando detalle de los bienes a continuación:', 20, 100);
	    doc.text(`${name}  ${brand}  ${model}  `, 20, 110);
	    doc.text(`${description} `, 20, 120);
	    doc.text('Los  datos  personales  expresados  en  el  presente documento  serán  incorporados  al ', 20, 130);
	    doc.text('Sistema  REGISTRO  DE  BIENES  MUEBLES, cuyo  uso  y  fin  del  comunicado  en los ', 20, 140);
	    doc.text(`supuestos  previstos  legales  o con  objeto  de  satisfacer  las  solicitudes  de  publicidad `, 20, 150);
	    doc.text(`formal  que  se  formulen  de  acuerdo  con  la  legislación  registral.  En  cuanto  resulte`, 20, 160);
	    doc.text('compatiblecon  la  legislación  específica  del  registro,  se  reconoce   a  los  interesados  ', 20, 170);
	    doc.text('los derechos  de  acceso,  rectificación,  cancelación  y  oposición  establecidos  en  la', 20, 180);
	    doc.text('reglamentación  citada  pudiendo  ejercitarlos  dirigiendo  un  escrito  al  registro.  La', 20, 190);
	    doc.text('obtención  y  tratamiento  de  sus datos  en  formas  indicadas,  es  condición  necesaria', 20, 200);
	    doc.text('para  la  presentación  de  los  servicios  a  cargo  del  registro.  Exime  de  todo  tipo  de', 20, 210);
	    doc.text('responsabilidad legal, PENAL O CIVIL, al  sistema de Registro de bienes Muebles  y sus', 20, 220);
	    doc.text('creadores  por  los  bienes  inscriptos.  Tiene pleno  conocimiento  que  lo  plasmado  por', 20, 230);
	    doc.text('el registrante en el presente sistema  es  bajo  juramento  y  entiende  las penas  de falso ', 20, 240);
	    doc.text('testimonio  que  se  pudiera  suceder  de  su  parte.  Se  finaliza  el  acto  previa  lectura', 20, 250);
	    doc.text('y ratificación de todo  su  contenido firman para constancia  el  propietario de  los bienes', 20, 260);
	    doc.text('registrados, dando fe de ello el Registro de bienes Muebles, RBM. Conste. - -', 20, 270);
		doc.line(20, 280, 190, 280);

	    doc.save('Acta de Registro.pdf');
	}

    return (
    	<Layout>
            <div className='pdf-generate'>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', lineHeight: '32px', paddingBottom: '64px' }}>
                    <h2 style={{ fontSize: '27px', textAlign: 'center', padding: '48px 0', fontWeight: '700' }}>ACTA DE REGISTRO DE BIENES MUEBLES</h2>
                    <p style={{ fontSize: '16px', width: '672px', textAlign: 'left' }}>
                        En la localidad de Río Primero, a los {day} día(s) del mes de {month} del año dos mil veinticuatro, en el sistema RBM,
                        REGISTRO DE BIENES MUEBLES  lleva adelante y a los efectos legales posteriores se le DESIGNA al
                        Sr/a. {fullName} Domicilio {address} DNI Nª {cardId} teléfono Nª {phone} los códigos QR suplidos por el sistema. Quien suscribe declara que tiene
                        interés legítimo, ser propietario de los bienes muebles que solicita la identificación mediante el sistema QR,
                    </p>
                    <p style={{ fontSize: '16px', width: '672px', textAlign: 'left' }}>Dando detalle de los bienes a continuación: </p>
                    <p style={{ fontSize: '16px', width: '672px', textAlign: 'left', fontWeight: '500' }}>{name} {brand} {model}</p>
					<p style={{ fontSize: '16px', width: '672px', textAlign: 'left', fontWeight: '500' }}>{description}</p>
                    <p style={{ fontSize: '16px', width: '672px', textAlign: 'left', paddingBottom: '32px', borderBottom: '2px solid #000' }}>Los datos personales expresados en el presente documento serán incorporados al Sistema
                    REGISTRO DE BIENES MUEBLES, cuyo uso y fin del comunicado en los supuestos previstos legales o con objeto de satisfacer las solicitudes
                    de publicidad formal que se formulen de acuerdo con la legislación registral. En cuanto resulte compatible con la legislación específica
                    del registro, se reconoce a los interesados los derechos de acceso, rectificación, cancelación y oposición establecidos en la
                    reglamentación citada pudiendo ejercitarlos dirigiendo un escrito al registro. La obtención y tratamiento de sus datos en formas
                    indicadas, es condición necesaria para la presentación de los servicios a cargo del registro. Exime de todo tipo de responsabilidad
                    legal, PENAL O CIVIL, al sistema de Registro de bienes Muebles y sus creadores por los bienes inscriptos. Tiene pleno conocimiento que
                    lo plasmado por el registrante en el presente sistema es bajo juramento y entiende las penas de falso testimonio que se pudiera suceder
                    de su parte. Se finaliza el acto previa lectura y ratificación de todo su contenido firman para constancia el propietario de los bienes
                    registrados, dando fe de ello el Registro de bienes Muebles, RBM. Conste. - - </p>
                </div>
                <button className='print-button' onClick={GeneratePDF}>Imprimir</button>
            </div>
        </Layout>
    )
}

const mapStateToProps = state => ({
	profile: state.Profile.profile,
	user: state.Auth.user,
	good: state.Good.good,
})

export default connect(mapStateToProps, {
	get_user_profile,
	get_good
}) (RegisterActGood)