import {
    SET_PREFERENCE_LOADING,
    REMOVE_PREFERENCE_LOADING,
    CREATE_PREFERENCE_SUCCESS,
    CREATE_PREFERENCE_FAIL,
} from '../actions/types'


const initialState = {
    preference: null,
    payLoading: false,
}

export default function Payment(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case SET_PREFERENCE_LOADING:
            return {
                ...state,
                payLoading: true,
            }
        case REMOVE_PREFERENCE_LOADING:
            return {
                ...state,
                payLoading: false,
            }
        case CREATE_PREFERENCE_SUCCESS:
            return {
                ...state,
                preference: payload.preference,
            }
        case CREATE_PREFERENCE_FAIL:
            return {
                ...state,
                preference: null,
            }
        default:
            return state
    }
}